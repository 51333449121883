import React, { useEffect, useRef } from "react";
import {
  StyledEthicalCarousel,
  StyledEthicalCarouselItem,
  StyledEthicalCarouselItemDescription,
  StyledEthicalCarouselItemIcon,
  StyledEthicalCarouselItems,
  StyledEthicalCarouselItemTitle,
  StyledEthicalCarouselPagination,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import { StyledBigText } from "../../commons/Typography";
import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

export default ({ title, items }) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();

  useEffect(() => {
    if (sliderEl) {
      const slider = new Swiper(sliderEl.current, {
        allowTouchMove: "ontouchstart" in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 1,
        pagination: {
          el: sliderPaginationEl.current,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
        },
      });

      Object.values(sliderPaginationEl.current.children).map((item, index) =>
        item.addEventListener("click", (e) => {
          e.preventDefault();
          slider.slideTo(index);
          return false;
        }),
      );
    }
  }, [sliderEl]);

  return (
    <StyledEthicalCarousel>
      <StyledGridRow>
        <StyledBigText dangerouslySetInnerHTML={{ __html: title }} />
        <StyledEthicalCarouselItems ref={sliderEl} className="swiper-container">
          <div className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledEthicalCarouselItem key={index} className="swiper-slide">
                <StyledEthicalCarouselItemIcon>
                  <img src={item.icon} loading="lazy" />
                </StyledEthicalCarouselItemIcon>
                <StyledEthicalCarouselItemTitle
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <StyledEthicalCarouselItemDescription
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </StyledEthicalCarouselItem>
            ))}
          </div>
          <StyledEthicalCarouselPagination
            className="swiper-pagination"
            ref={sliderPaginationEl}
          />
        </StyledEthicalCarouselItems>
      </StyledGridRow>
    </StyledEthicalCarousel>
  );
};
